import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type IAddress = {
  __typename?: 'Address';
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

export type IAllocation = {
  orderItemId: Scalars['Float'];
  amount: Scalars['Float'];
};

export type IAttendance = {
  __typename?: 'Attendance';
  id: Scalars['ID'];
  courseSession: ICourseSession;
  user: IUser;
};

/** Age groups or category of people attending */
export enum IAttendanceType {
  Children = 'CHILDREN',
  Adults = 'ADULTS'
}

export type IBasket = {
  __typename?: 'Basket';
  id?: Maybe<Scalars['ID']>;
  subTotal: Scalars['Int'];
  tax?: Maybe<Scalars['Int']>;
  discountValue?: Maybe<Scalars['Int']>;
  discountTotal?: Maybe<Scalars['Int']>;
  creditTotal?: Maybe<Scalars['Int']>;
  chargeTotal?: Maybe<Scalars['Int']>;
  payLater?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  orderModifiers?: Maybe<Array<IOrderModifier>>;
  user?: Maybe<IUser>;
  order?: Maybe<IOrder>;
  items?: Maybe<Array<ILineItem>>;
  creditItems?: Maybe<Array<ICreditItem>>;
  feeItems?: Maybe<Array<IFeeItem>>;
  siteId?: Maybe<ISiteId>;
};

export type IBasketResponse = {
  __typename?: 'BasketResponse';
  basket?: Maybe<IBasket>;
  errors?: Maybe<Array<IFieldError>>;
};

export type ICourse = IICourse & {
  __typename?: 'Course';
  id: Scalars['Float'];
  name: Scalars['String'];
  ageFrom?: Maybe<Scalars['Int']>;
  ageTo?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  level?: Maybe<ILevel>;
  price: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  attendanceTypes: Array<IAttendanceType>;
  type?: Maybe<Scalars['String']>;
  displayStatus?: Maybe<IDisplayStatus>;
  siteId?: Maybe<ISiteId>;
  originalPrice?: Maybe<Scalars['Int']>;
  currentPrice?: Maybe<Scalars['Int']>;
  hasTasterClasses?: Maybe<Scalars['Boolean']>;
  reducePriceOverTime?: Maybe<Scalars['Boolean']>;
  fullyBooked?: Maybe<Scalars['Boolean']>;
  tasterPrice?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<IUser>>;
  term?: Maybe<ITerm>;
  availableSpaces?: Maybe<Scalars['Int']>;
  orderItems?: Maybe<Array<IOrderItem>>;
  lineItems?: Maybe<ILineItem>;
  exams?: Maybe<Array<IExam>>;
  sessions?: Maybe<Array<ICourseSession>>;
  videos?: Maybe<Array<IVideo>>;
  courseGroup?: Maybe<ICourseGroup>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  depositStart?: Maybe<Scalars['DateTime']>;
  depositEnd?: Maybe<Scalars['DateTime']>;
  isAcceptingDeposits?: Maybe<Scalars['Boolean']>;
  depositPrice?: Maybe<Scalars['Int']>;
  weeks?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['String']>;
  listStyle?: Maybe<ICourseListStyle>;
  danceType?: Maybe<IDanceTypeEnum>;
  location?: Maybe<ILocationEnum>;
  futureCourseSessions?: Maybe<Array<ICourseSession>>;
};

export type ICourseGroup = {
  __typename?: 'CourseGroup';
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  courses?: Maybe<Array<ICourseResult>>;
  attendanceTypes?: Maybe<Array<IAttendanceType>>;
  locations?: Maybe<Array<ILocationEnum>>;
  courseTypes?: Maybe<Array<Scalars['String']>>;
  active?: Maybe<Scalars['Boolean']>;
  displayStatus?: Maybe<IDisplayStatus>;
  siteId?: Maybe<ISiteId>;
  danceType?: Maybe<IDanceTypeEnum>;
  minOriginalPrice?: Maybe<Scalars['Float']>;
  maxOriginalPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
};

export type ICourseInput = {
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  ageFrom?: Maybe<Scalars['Float']>;
  ageTo?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  level?: Maybe<ILevel>;
  price?: Maybe<Scalars['Int']>;
};

export enum ICourseListStyle {
  Summary = 'SUMMARY',
  Sessions = 'SESSIONS'
}

export type ICourseResponse = {
  __typename?: 'CourseResponse';
  courses?: Maybe<Array<ICourseResult>>;
  courseGroups?: Maybe<Array<ICourseGroup>>;
};

export type ICourseResult = IStudioCourse | IOnlineCourse;

export type ICourseSearchInput = {
  name?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  attendanceTypes?: Maybe<IAttendanceType>;
  displayStatus?: Maybe<IDisplayStatus>;
  danceType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type ICourseSession = {
  __typename?: 'CourseSession';
  id: Scalars['Float'];
  meetingId?: Maybe<Scalars['String']>;
  startDateTime: Scalars['DateTime'];
  price?: Maybe<Scalars['Float']>;
  endDateTime: Scalars['DateTime'];
  course?: Maybe<ICourseResult>;
  attendance?: Maybe<IAttendance>;
  lineItems?: Maybe<ILineItem>;
  users?: Maybe<Array<IUser>>;
  purchasedCourse?: Maybe<Scalars['Boolean']>;
};

export type ICourseVideoInput = {
  courseId?: Maybe<Scalars['Float']>;
};

export type ICreatePaymentInput = {
  orderId?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodType: IPaymentMethodType;
};

export type ICreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  weChatId: Scalars['String'];
  heardAboutUs?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type ICreditItem = {
  __typename?: 'CreditItem';
  id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  value: Scalars['Int'];
};

/** Different dance types */
export enum IDanceTypeEnum {
  Chinese = 'CHINESE',
  Ballet = 'BALLET',
  Latin = 'LATIN',
  Kpop = 'KPOP',
  Belly = 'BELLY',
  Jazz = 'JAZZ',
  Hiphop = 'HIPHOP'
}


export type IDefaultResponse = {
  __typename?: 'DefaultResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<IFieldError>>;
};

export enum IDisplayStatus {
  Draft = 'DRAFT',
  Preview = 'PREVIEW',
  Live = 'LIVE'
}

export type IExam = {
  __typename?: 'Exam';
  id: Scalars['Float'];
  price?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  course?: Maybe<ICourse>;
  examGroup?: Maybe<IExamGroup>;
  users?: Maybe<Array<IUser>>;
  date: Scalars['DateTime'];
  active?: Maybe<Scalars['Boolean']>;
  address: IAddress;
  lineItems?: Maybe<ILineItem>;
  attendanceType: IAttendanceType;
};

export type IExamGroup = {
  __typename?: 'ExamGroup';
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  exams?: Maybe<Array<IExam>>;
};

export type IFeeItem = {
  __typename?: 'FeeItem';
  id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  value: Scalars['Float'];
  basket?: Maybe<IBasket>;
};

export type IFieldError = {
  __typename?: 'FieldError';
  path: Scalars['String'];
  message: Scalars['String'];
};

export type IFixPaymentsInput = {
  orderId: Scalars['Float'];
  /** Payment transaction to allocate. If null, use resetAllocations to reset payment transactions */
  paymentTransactionId?: Maybe<Scalars['Float']>;
};

/** Where a user heard about us */
export enum IHeardAboutUs {
  Wechat = 'WECHAT',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Friend = 'FRIEND',
  Other = 'OTHER'
}

export type IICourse = {
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  ageFrom?: Maybe<Scalars['Int']>;
  ageTo?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  level?: Maybe<ILevel>;
  price?: Maybe<Scalars['Int']>;
};

export type IILineItemExtraChildInfo = {
  dateOfBirth?: Maybe<Scalars['String']>;
  lineItemId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Item type which can be added to a basket */
export enum IItemType {
  Exam = 'EXAM',
  Course = 'COURSE',
  CourseSession = 'COURSE_SESSION',
  Coursesession = 'COURSESESSION'
}

/** Ability level of the course */
export enum ILevel {
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
  KidsFoundation = 'KIDS_FOUNDATION',
  Kids_1 = 'KIDS_1',
  Kids_2 = 'KIDS_2'
}

export type ILineItem = {
  __typename?: 'LineItem';
  id?: Maybe<Scalars['ID']>;
  basket?: Maybe<IBasket>;
  course?: Maybe<ICourse>;
  exam?: Maybe<IExam>;
  courseSession?: Maybe<ICourseSession>;
  orderItem?: Maybe<IOrderItem>;
  price?: Maybe<Scalars['Int']>;
  chargeTotal?: Maybe<Scalars['Int']>;
  discountValue?: Maybe<Scalars['Int']>;
  payDeposit?: Maybe<Scalars['Boolean']>;
  creditAmount?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  orderModifier?: Maybe<IOrderModifier>;
};

/** Different locations */
export enum ILocationEnum {
  Finchley = 'FINCHLEY',
  Hendon = 'HENDON',
  Hammersmith = 'HAMMERSMITH',
  Orpington = 'ORPINGTON',
  Bromley = 'BROMLEY',
  Canadawater = 'CANADAWATER',
  CanadaWater = 'CANADA_WATER',
  Zoom = 'ZOOM'
}

export type ILoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type IMutation = {
  __typename?: 'Mutation';
  createOrder: IOrderResponse;
  updateOrder: IOrder;
  deleteOrder: IOrder;
  placeOrder: IPlaceOrderResponse;
  register: IUserResponse;
  passwordResetRequest: IPasswordResetResponse;
  passwordReset: IPasswordResetResponse;
  updatePassword: IPasswordResetResponse;
  login: IUserResponse;
  logout: Scalars['Boolean'];
  initBasket: IBasketResponse;
  destroyBasket: Scalars['Boolean'];
  addItem: IBasketResponse;
  removeItem: IBasketResponse;
  createCourse: ICourse;
  updateCourse: ICourse;
  deleteCourse: ICourse;
  addUsersToCourse: Array<IUser>;
  addCoursesToUser: Array<ICourse>;
  removeUsersFromCourse: Array<IUser>;
  createUser: IUser;
  updateUser: IUser;
  deleteUser: IUser;
  /** Allocate a payment transaction across an order */
  allocateTransactions: Scalars['Boolean'];
  /** Update orderitem calculations based on assigned payment transactions and allocations */
  updateOrderItemPayments: Scalars['Boolean'];
  takeOrderPayment: IOrderResponse;
  createPaymentIntent: IPaymentIntent;
  updatePaymentIntent: Scalars['Boolean'];
  confirmPaymentIntent: Scalars['Boolean'];
  cancelPaymentIntent: Scalars['Boolean'];
  addItemToUser: IDefaultResponse;
  removeItemFromUser: IDefaultResponse;
  logEvent: Scalars['Boolean'];
};


export type IMutationUpdateOrderArgs = {
  data: IOrderInput;
};


export type IMutationDeleteOrderArgs = {
  data: IOrderInput;
};


export type IMutationPlaceOrderArgs = {
  data: IPlaceOrderInput;
};


export type IMutationRegisterArgs = {
  data: ICreateUserInput;
};


export type IMutationPasswordResetRequestArgs = {
  data: IPasswordResetReqInput;
};


export type IMutationPasswordResetArgs = {
  data: IPasswordResetInput;
};


export type IMutationUpdatePasswordArgs = {
  password: Scalars['String'];
  userId: Scalars['Float'];
};


export type IMutationLoginArgs = {
  data: ILoginInput;
};


export type IMutationAddItemArgs = {
  payDeposit?: Maybe<Scalars['Boolean']>;
  itemType: Scalars['String'];
  itemId: Scalars['Float'];
};


export type IMutationRemoveItemArgs = {
  itemType: Scalars['String'];
  itemId: Scalars['Float'];
};


export type IMutationCreateCourseArgs = {
  data: ICourseInput;
};


export type IMutationUpdateCourseArgs = {
  data: ICourseInput;
};


export type IMutationDeleteCourseArgs = {
  data: ICourseInput;
};


export type IMutationAddUsersToCourseArgs = {
  courseId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type IMutationAddCoursesToUserArgs = {
  userId: Scalars['Int'];
  courseIds: Array<Scalars['Int']>;
};


export type IMutationRemoveUsersFromCourseArgs = {
  userIds: Array<Scalars['Int']>;
  courseId: Scalars['Int'];
};


export type IMutationCreateUserArgs = {
  data: ICreateUserInput;
};


export type IMutationUpdateUserArgs = {
  data: IUpdateUserInput;
  id: Scalars['String'];
};


export type IMutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type IMutationAllocateTransactionsArgs = {
  data: IFixPaymentsInput;
};


export type IMutationUpdateOrderItemPaymentsArgs = {
  data: IFixPaymentsInput;
};


export type IMutationTakeOrderPaymentArgs = {
  data: ITakeOrderPaymentInput;
};


export type IMutationCreatePaymentIntentArgs = {
  data: ICreatePaymentInput;
};


export type IMutationUpdatePaymentIntentArgs = {
  id: Scalars['String'];
};


export type IMutationConfirmPaymentIntentArgs = {
  id: Scalars['String'];
};


export type IMutationCancelPaymentIntentArgs = {
  id: Scalars['String'];
};


export type IMutationAddItemToUserArgs = {
  data: IUserItemInput;
};


export type IMutationRemoveItemFromUserArgs = {
  data: IUserItemInput;
};


export type IMutationLogEventArgs = {
  key: Scalars['String'];
  metadata: Scalars['String'];
};

export type IOnlineCourse = IICourse & {
  __typename?: 'OnlineCourse';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  ageFrom?: Maybe<Scalars['Int']>;
  ageTo?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  level?: Maybe<ILevel>;
  price?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  attendanceTypes: Array<IAttendanceType>;
  type?: Maybe<Scalars['String']>;
  displayStatus?: Maybe<IDisplayStatus>;
  siteId?: Maybe<ISiteId>;
  originalPrice?: Maybe<Scalars['Int']>;
  currentPrice?: Maybe<Scalars['Int']>;
  hasTasterClasses?: Maybe<Scalars['Boolean']>;
  reducePriceOverTime?: Maybe<Scalars['Boolean']>;
  fullyBooked?: Maybe<Scalars['Boolean']>;
  tasterPrice?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<IUser>>;
  term?: Maybe<ITerm>;
  availableSpaces?: Maybe<Scalars['Int']>;
  orderItems?: Maybe<Array<IOrderItem>>;
  lineItems?: Maybe<ILineItem>;
  exams?: Maybe<Array<IExam>>;
  sessions?: Maybe<Array<ICourseSession>>;
  videos?: Maybe<Array<IVideo>>;
  courseGroup?: Maybe<ICourseGroup>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  depositStart?: Maybe<Scalars['DateTime']>;
  depositEnd?: Maybe<Scalars['DateTime']>;
  isAcceptingDeposits?: Maybe<Scalars['Boolean']>;
  depositPrice?: Maybe<Scalars['Int']>;
  weeks?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['String']>;
  listStyle?: Maybe<ICourseListStyle>;
  danceType?: Maybe<IDanceTypeEnum>;
  location?: Maybe<ILocationEnum>;
  futureCourseSessions?: Maybe<Array<ICourseSession>>;
  zoomMeeting?: Maybe<IZoomMeeting>;
};

export type IOrder = {
  __typename?: 'Order';
  id: Scalars['ID'];
  status: IOrderStatus;
  subTotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  orderModifiers?: Maybe<Array<IOrderModifier>>;
  discountValue?: Maybe<Scalars['Int']>;
  discountTotal?: Maybe<Scalars['Int']>;
  creditTotal?: Maybe<Scalars['Int']>;
  selectedPaymentMethodType: IPaymentMethodType;
  total?: Maybe<Scalars['Int']>;
  creditItems?: Maybe<Array<ICreditItem>>;
  baskets?: Maybe<Array<IBasket>>;
  feeItems?: Maybe<Array<IFeeItem>>;
  items?: Maybe<Array<IOrderItem>>;
  paymentTransactions?: Maybe<Array<IPaymentTransaction>>;
  user: IUser;
  createdAt: Scalars['DateTime'];
  siteId?: Maybe<ISiteId>;
  paidAmount?: Maybe<Scalars['Float']>;
  outstandingAmount?: Maybe<Scalars['Float']>;
  isSettled?: Maybe<Scalars['Boolean']>;
};

export type IOrderInput = {
  id?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['Float']>;
};

export type IOrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  order?: Maybe<IOrder>;
  course?: Maybe<ICourseResult>;
  exam?: Maybe<IExam>;
  courseSession?: Maybe<ICourseSession>;
  payDeposit?: Maybe<Scalars['Boolean']>;
  lineItemId?: Maybe<Scalars['Float']>;
  paymentAllocations?: Maybe<Array<IPaymentAllocation>>;
  lineItems?: Maybe<Array<ILineItem>>;
  price?: Maybe<Scalars['Int']>;
  discountValue?: Maybe<Scalars['Int']>;
  creditAmount?: Maybe<Scalars['Int']>;
  chargeTotal?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Int']>;
  orderModifier?: Maybe<IOrderModifier>;
  amountPaid?: Maybe<Scalars['Float']>;
  amountOutstanding?: Maybe<Scalars['Float']>;
};

export type IOrderModifier = {
  __typename?: 'OrderModifier';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  type?: Maybe<IOrderModifierType>;
  valueType: IOrderModifierValueType;
  value: Scalars['Float'];
  enabled?: Maybe<Scalars['Boolean']>;
  basket?: Maybe<IOrderModifier>;
};

export enum IOrderModifierType {
  OrderItem = 'ORDER_ITEM',
  BasketItem = 'BASKET_ITEM',
  Basket = 'BASKET',
  Course = 'COURSE'
}

export enum IOrderModifierValueType {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED'
}

export type IOrderResponse = {
  __typename?: 'OrderResponse';
  order?: Maybe<IOrder>;
  orders?: Maybe<Array<IOrder>>;
  errors?: Maybe<Array<IFieldError>>;
};

export enum IOrderStatus {
  Init = 'INIT',
  Cancelled = 'CANCELLED',
  PaymentPending = 'PAYMENT_PENDING',
  Success = 'SUCCESS',
  Rejected = 'REJECTED',
  Error = 'ERROR'
}

export type IPasswordResetInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  password: Scalars['String'];
};

export type IPasswordResetReqInput = {
  email: Scalars['String'];
};

export type IPasswordResetResponse = {
  __typename?: 'PasswordResetResponse';
  success?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<IFieldError>>;
};

export type IPayment = {
  paymentMethodType: IPaymentMethodType;
  amount: Scalars['Float'];
};

export type IPaymentAllocation = {
  __typename?: 'PaymentAllocation';
  id: Scalars['ID'];
  paymentTransaction: IPaymentTransaction;
  orderItem: IOrderItem;
};

export type IPaymentIntent = {
  __typename?: 'PaymentIntent';
  id: Scalars['ID'];
  stripeId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amountCapturable: Scalars['Float'];
  amountReceived: Scalars['Float'];
  cancelledAt?: Maybe<Scalars['String']>;
  cancelledReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  paymentMethod: Scalars['String'];
  user: IUser;
};

export type IPaymentIntentInput = {
  id: Scalars['Float'];
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type IPaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  card: IStripeCard;
};

export enum IPaymentMethodType {
  Cash = 'CASH',
  Card = 'CARD',
  Wechat = 'WECHAT',
  Transfer = 'TRANSFER',
  Credit = 'CREDIT',
  Correction = 'CORRECTION'
}

export type IPaymentMethodsResponse = {
  __typename?: 'PaymentMethodsResponse';
  paymentMethods?: Maybe<Array<IPaymentMethod>>;
};

export type IPaymentNextAction = {
  __typename?: 'PaymentNextAction';
  type?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<IRedirectToUrl>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type IPaymentTransaction = {
  __typename?: 'PaymentTransaction';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<IPaymentTransactionStatus>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodType: IPaymentMethodType;
  order: IOrder;
  paymentAllocations?: Maybe<Array<IPaymentAllocation>>;
  amount?: Maybe<Scalars['Float']>;
  depositPayment?: Maybe<Scalars['Boolean']>;
  createdById: Scalars['Float'];
  updatedById: Scalars['Float'];
};

export enum IPaymentTransactionStatus {
  Init = 'INIT',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Cancelled = 'CANCELLED',
  Other = 'OTHER'
}

export type IPlaceOrderInput = {
  orderId?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  currency: Scalars['String'];
  paymentMethod?: Maybe<Scalars['String']>;
  paymentMethodType: IPaymentMethodType;
  lineItemExtraChildInfo?: Maybe<Array<IILineItemExtraChildInfo>>;
};

export type IPlaceOrderResponse = {
  __typename?: 'PlaceOrderResponse';
  status?: Maybe<IOrderStatus>;
  nextAction?: Maybe<IPaymentNextAction>;
  paymentTransactionStatus?: Maybe<IPaymentTransactionStatus>;
  order?: Maybe<IOrder>;
  errors?: Maybe<Array<IFieldError>>;
};

export type IQuery = {
  __typename?: 'Query';
  getOrder: IOrder;
  getOrders: IOrderResponse;
  me: IUserResponse;
  meDetails: IUserResponse;
  meCredit: IUserResponse;
  loginGet: IUserResponse;
  getBasket: IBasketResponse;
  getCourse: ICourseResult;
  getCourses: ICourseResponse;
  getCourseVideos: Array<IVideo>;
  getTasterCourses: Array<ICourseResult>;
  getSearchables: ISearchable;
  user: IUser;
  users: Array<IUser>;
  getUnsettledOrders: IOrderResponse;
  getPaymentIntent: IPaymentIntent;
  getPaymentMethods: IPaymentMethodsResponse;
  getExams: Array<IExamGroup>;
  getExam: IExam;
  getCourseGroup: ICourseGroup;
  getCourseSession: ICourseSession;
};


export type IQueryGetOrderArgs = {
  data: IOrderInput;
};


export type IQueryGetOrdersArgs = {
  data: IOrderInput;
};


export type IQueryLoginGetArgs = {
  data: ILoginInput;
};


export type IQueryGetCourseArgs = {
  id: Scalars['Float'];
};


export type IQueryGetCoursesArgs = {
  data: ICourseSearchInput;
};


export type IQueryGetCourseVideosArgs = {
  data: ICourseVideoInput;
};


export type IQueryGetTasterCoursesArgs = {
  data: ICourseSearchInput;
};


export type IQueryUserArgs = {
  id: Scalars['String'];
};


export type IQueryGetUnsettledOrdersArgs = {
  data: IOrderInput;
};


export type IQueryGetPaymentIntentArgs = {
  data: IPaymentIntentInput;
};


export type IQueryGetExamArgs = {
  id: Scalars['Float'];
};


export type IQueryGetCourseGroupArgs = {
  id: Scalars['Float'];
  displayStatus?: Maybe<IDisplayStatus>;
};


export type IQueryGetCourseSessionArgs = {
  id: Scalars['Float'];
};

export type IRedirectToUrl = {
  __typename?: 'RedirectToURL';
  url?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
};

export enum IRole {
  Admin = 'ADMIN',
  Finance = 'FINANCE',
  Engineering = 'ENGINEERING',
  User = 'USER'
}

export type ISearchable = {
  __typename?: 'Searchable';
  locations?: Maybe<Array<ILocationEnum>>;
  danceTypes?: Maybe<Array<IDanceTypeEnum>>;
};

/** Site identifier to handle multiple sites */
export enum ISiteId {
  Ukcpa = 'UKCPA',
  Cats = 'CATS'
}

export type IStripeCard = {
  __typename?: 'StripeCard';
  brand: Scalars['String'];
  country: Scalars['String'];
  expMonth: Scalars['Float'];
  expYear: Scalars['Float'];
  last4: Scalars['String'];
};

export type IStudioCourse = IICourse & {
  __typename?: 'StudioCourse';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  ageFrom?: Maybe<Scalars['Int']>;
  ageTo?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  level?: Maybe<ILevel>;
  price?: Maybe<Scalars['Int']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbImage?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  attendanceTypes: Array<IAttendanceType>;
  type?: Maybe<Scalars['String']>;
  displayStatus?: Maybe<IDisplayStatus>;
  siteId?: Maybe<ISiteId>;
  originalPrice?: Maybe<Scalars['Int']>;
  currentPrice?: Maybe<Scalars['Int']>;
  hasTasterClasses?: Maybe<Scalars['Boolean']>;
  reducePriceOverTime?: Maybe<Scalars['Boolean']>;
  fullyBooked?: Maybe<Scalars['Boolean']>;
  tasterPrice?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<IUser>>;
  term?: Maybe<ITerm>;
  availableSpaces?: Maybe<Scalars['Int']>;
  orderItems?: Maybe<Array<IOrderItem>>;
  lineItems?: Maybe<ILineItem>;
  exams?: Maybe<Array<IExam>>;
  sessions?: Maybe<Array<ICourseSession>>;
  videos?: Maybe<Array<IVideo>>;
  courseGroup?: Maybe<ICourseGroup>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
  depositStart?: Maybe<Scalars['DateTime']>;
  depositEnd?: Maybe<Scalars['DateTime']>;
  isAcceptingDeposits?: Maybe<Scalars['Boolean']>;
  depositPrice?: Maybe<Scalars['Int']>;
  weeks?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['String']>;
  listStyle?: Maybe<ICourseListStyle>;
  danceType?: Maybe<IDanceTypeEnum>;
  location?: Maybe<ILocationEnum>;
  futureCourseSessions?: Maybe<Array<ICourseSession>>;
  instructions?: Maybe<Scalars['String']>;
  address: IAddress;
};

export type ITakeOrderPaymentInput = {
  orderId: Scalars['Float'];
  allocations: Array<IAllocation>;
  payment: IPayment;
  note?: Maybe<Scalars['String']>;
};

export type ITerm = {
  __typename?: 'Term';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  courses?: Maybe<Array<ICourse>>;
};

export type IToken = {
  __typename?: 'Token';
  user: IUser;
  token: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expires: Scalars['Float'];
};

export type IUpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type IUser = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  age?: Maybe<Scalars['Float']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  weChatId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  catsStripeCustomerId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  heardAboutUs?: Maybe<IHeardAboutUs>;
  role?: Maybe<IRole>;
  roles?: Maybe<Array<IRole>>;
  profileImageUrl?: Maybe<Scalars['String']>;
  googleId?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['String']>;
  address?: Maybe<IAddress>;
  courses?: Maybe<Array<ICourseResult>>;
  courseSessions?: Maybe<Array<ICourseSession>>;
  exams?: Maybe<Array<IExam>>;
  attendance: Array<IAttendance>;
  orders?: Maybe<Array<IOrder>>;
  baskets?: Maybe<Array<IBasket>>;
  paymentIntents: Array<IPaymentIntent>;
  children?: Maybe<Array<IUser>>;
  creditTotal?: Maybe<Scalars['Float']>;
  creditUsed?: Maybe<Scalars['Float']>;
  creditAvailable?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Array<IToken>>;
};

export type IUserItemInput = {
  userId: Scalars['Float'];
  itemId: Scalars['Float'];
  itemType: IItemType;
};

export type IUserResponse = {
  __typename?: 'UserResponse';
  user?: Maybe<IUser>;
  token?: Maybe<Scalars['String']>;
  children?: Maybe<Array<IUser>>;
  creditAvailable?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<IFieldError>>;
};

export type IVideo = {
  __typename?: 'Video';
  id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  course?: Maybe<ICourse>;
};

export type IZoomMeeting = {
  __typename?: 'ZoomMeeting';
  meetingId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type IInitBasketMutationVariables = Exact<{ [key: string]: never; }>;


export type IInitBasketMutation = (
  { __typename?: 'Mutation' }
  & { initBasket: (
    { __typename?: 'BasketResponse' }
    & { basket?: Maybe<(
      { __typename?: 'Basket' }
      & IBasketFieldsFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IGetBasketQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetBasketQuery = (
  { __typename?: 'Query' }
  & { getBasket: (
    { __typename?: 'BasketResponse' }
    & { basket?: Maybe<(
      { __typename?: 'Basket' }
      & IBasketFieldsFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IAddItemMutationVariables = Exact<{
  itemId: Scalars['Float'];
  itemType: Scalars['String'];
  payDeposit?: Maybe<Scalars['Boolean']>;
}>;


export type IAddItemMutation = (
  { __typename?: 'Mutation' }
  & { addItem: (
    { __typename?: 'BasketResponse' }
    & { basket?: Maybe<(
      { __typename?: 'Basket' }
      & IBasketFieldsFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IRemoveItemMutationVariables = Exact<{
  itemId: Scalars['Float'];
  itemType: Scalars['String'];
}>;


export type IRemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { removeItem: (
    { __typename?: 'BasketResponse' }
    & { basket?: Maybe<(
      { __typename?: 'Basket' }
      & IBasketFieldsFragmentFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IGetCourseVideosQueryVariables = Exact<{
  data: ICourseVideoInput;
}>;


export type IGetCourseVideosQuery = (
  { __typename?: 'Query' }
  & { getCourseVideos: Array<(
    { __typename?: 'Video' }
    & Pick<IVideo, 'id' | 'name' | 'description' | 'url' | 'provider'>
  )> }
);

export type IMeQueryVariables = Exact<{ [key: string]: never; }>;


export type IMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & IUserBasicFragmentFragment
    )> }
  ) }
);

export type ILoginGetQueryVariables = Exact<{
  data: ILoginInput;
}>;


export type ILoginGetQuery = (
  { __typename?: 'Query' }
  & { loginGet: (
    { __typename?: 'UserResponse' }
    & Pick<IUserResponse, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<IUser, 'email' | 'firstName' | 'lastName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type ILoginMutationVariables = Exact<{
  data: ILoginInput;
}>;


export type ILoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & Pick<IUserResponse, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<IUser, 'email' | 'firstName' | 'lastName'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type ILogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type ILogOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'logout'>
);

export type IRegisterMutationVariables = Exact<{
  data: ICreateUserInput;
}>;


export type IRegisterMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<IUser, 'firstName' | 'lastName' | 'email'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IGetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { getPaymentMethods: (
    { __typename?: 'PaymentMethodsResponse' }
    & { paymentMethods?: Maybe<Array<(
      { __typename?: 'PaymentMethod' }
      & Pick<IPaymentMethod, 'id'>
      & { card: (
        { __typename?: 'StripeCard' }
        & Pick<IStripeCard, 'brand' | 'last4' | 'expYear' | 'expMonth' | 'country'>
      ) }
    )>> }
  ) }
);

export type IBasketFieldsFragmentFragment = (
  { __typename?: 'Basket' }
  & Pick<IBasket, 'id' | 'discountValue' | 'discountTotal' | 'creditTotal' | 'subTotal' | 'tax' | 'total' | 'chargeTotal' | 'payLater'>
  & { items?: Maybe<Array<(
    { __typename?: 'LineItem' }
    & ILineItemFragmentFragment
  )>>, creditItems?: Maybe<Array<(
    { __typename?: 'CreditItem' }
    & Pick<ICreditItem, 'id' | 'description' | 'value'>
  )>>, feeItems?: Maybe<Array<(
    { __typename?: 'FeeItem' }
    & Pick<IFeeItem, 'id' | 'description' | 'value'>
  )>>, orderModifiers?: Maybe<Array<(
    { __typename?: 'OrderModifier' }
    & IOrderModifierFragmentFragment
  )>> }
);

export type ILineItemFragmentFragment = (
  { __typename?: 'LineItem' }
  & Pick<ILineItem, 'id' | 'price' | 'discountValue' | 'totalPrice'>
  & { course?: Maybe<(
    { __typename?: 'Course' }
    & Pick<ICourse, 'id' | 'name' | 'price' | 'currentPrice' | 'originalPrice' | 'active' | 'ageFrom' | 'level' | 'attendanceTypes'>
    & { courseGroup?: Maybe<(
      { __typename?: 'CourseGroup' }
      & Pick<ICourseGroup, 'name'>
    )> }
  )>, courseSession?: Maybe<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'price'>
    & { course?: Maybe<(
      { __typename: 'StudioCourse' }
      & Pick<IStudioCourse, 'name' | 'attendanceTypes'>
    ) | (
      { __typename: 'OnlineCourse' }
      & Pick<IOnlineCourse, 'name' | 'attendanceTypes'>
    )> }
  )>, exam?: Maybe<(
    { __typename?: 'Exam' }
    & Pick<IExam, 'id' | 'name'>
  )>, orderModifier?: Maybe<(
    { __typename?: 'OrderModifier' }
    & IOrderModifierFragmentFragment
  )> }
);

export type IStudioCourseFragmentFragment = (
  { __typename?: 'StudioCourse' }
  & Pick<IStudioCourse, 'id' | 'name' | 'subtitle' | 'ageFrom' | 'ageTo' | 'active' | 'level' | 'price' | 'originalPrice' | 'currentPrice' | 'depositPrice' | 'fullyBooked' | 'thumbImage' | 'image' | 'shortDescription' | 'description' | 'attendanceTypes' | 'startDateTime' | 'endDateTime' | 'weeks' | 'order' | 'listStyle' | 'days' | 'location' | 'danceType' | 'hasTasterClasses' | 'tasterPrice' | 'isAcceptingDeposits' | 'instructions'>
  & { courseGroup?: Maybe<(
    { __typename?: 'CourseGroup' }
    & Pick<ICourseGroup, 'id' | 'name'>
  )>, videos?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<IVideo, 'id' | 'name' | 'description' | 'url' | 'provider'>
  )>>, futureCourseSessions?: Maybe<Array<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'startDateTime' | 'endDateTime'>
  )>>, sessions?: Maybe<Array<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'startDateTime' | 'endDateTime'>
  )>>, address: (
    { __typename?: 'Address' }
    & Pick<IAddress, 'line1' | 'line2' | 'postCode' | 'city' | 'county'>
  ) }
);

export type IOnlineCourseFragmentFragment = (
  { __typename?: 'OnlineCourse' }
  & Pick<IOnlineCourse, 'id' | 'name' | 'subtitle' | 'ageFrom' | 'ageTo' | 'active' | 'level' | 'price' | 'originalPrice' | 'currentPrice' | 'depositPrice' | 'fullyBooked' | 'thumbImage' | 'image' | 'shortDescription' | 'description' | 'attendanceTypes' | 'startDateTime' | 'endDateTime' | 'weeks' | 'order' | 'listStyle' | 'days' | 'hasTasterClasses' | 'isAcceptingDeposits' | 'danceType' | 'location' | 'tasterPrice'>
  & { courseGroup?: Maybe<(
    { __typename?: 'CourseGroup' }
    & Pick<ICourseGroup, 'id' | 'name'>
  )>, futureCourseSessions?: Maybe<Array<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'startDateTime' | 'endDateTime'>
  )>>, sessions?: Maybe<Array<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'startDateTime' | 'endDateTime'>
  )>>, videos?: Maybe<Array<(
    { __typename?: 'Video' }
    & Pick<IVideo, 'id' | 'name' | 'description' | 'url' | 'provider'>
  )>>, zoomMeeting?: Maybe<(
    { __typename?: 'ZoomMeeting' }
    & Pick<IZoomMeeting, 'meetingId' | 'password'>
  )> }
);

export type ICourseGroupFragmentFragment = (
  { __typename?: 'CourseGroup' }
  & Pick<ICourseGroup, 'id' | 'name' | 'thumbImage' | 'image' | 'shortDescription' | 'description' | 'minOriginalPrice' | 'maxOriginalPrice' | 'minPrice' | 'maxPrice' | 'attendanceTypes' | 'locations' | 'danceType' | 'courseTypes'>
  & { courses?: Maybe<Array<(
    { __typename: 'StudioCourse' }
    & IStudioCourseFragmentFragment
  ) | (
    { __typename: 'OnlineCourse' }
    & IOnlineCourseFragmentFragment
  )>> }
);

export type IExamFragmentFragment = (
  { __typename?: 'Exam' }
  & Pick<IExam, 'id' | 'name' | 'price' | 'description' | 'date' | 'attendanceType'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<IAddress, 'name' | 'line1' | 'line2' | 'city' | 'postCode'>
  ) }
);

export type IExamGroupFragmentFragment = (
  { __typename?: 'ExamGroup' }
  & Pick<IExamGroup, 'id' | 'name'>
  & { exams?: Maybe<Array<(
    { __typename?: 'Exam' }
    & IExamFragmentFragment
  )>> }
);

export type IOrderModifierFragmentFragment = (
  { __typename?: 'OrderModifier' }
  & Pick<IOrderModifier, 'name' | 'value' | 'valueType'>
);

export type IUserBasicFragmentFragment = (
  { __typename?: 'User' }
  & Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email' | 'profileImageUrl' | 'stripeCustomerId' | 'roles'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<IAddress, 'line1' | 'line2' | 'city' | 'county' | 'country' | 'postCode'>
  )> }
);

export type IUserDetailedFragmentFragment = (
  { __typename?: 'User' }
  & Pick<IUser, 'id' | 'firstName' | 'lastName' | 'email' | 'profileImageUrl' | 'password' | 'stripeCustomerId'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<IAddress, 'line1' | 'line2' | 'city' | 'county' | 'country' | 'postCode'>
  )>, courses?: Maybe<Array<(
    { __typename: 'StudioCourse' }
    & IStudioCourseFragmentFragment
  ) | (
    { __typename: 'OnlineCourse' }
    & IOnlineCourseFragmentFragment
  )>>, courseSessions?: Maybe<Array<(
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'id' | 'startDateTime' | 'endDateTime' | 'purchasedCourse'>
  )>>, exams?: Maybe<Array<(
    { __typename?: 'Exam' }
    & Pick<IExam, 'id' | 'name' | 'date' | 'attendanceType'>
    & { examGroup?: Maybe<(
      { __typename?: 'ExamGroup' }
      & Pick<IExamGroup, 'name'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<IAddress, 'name' | 'line1' | 'line2' | 'city' | 'county' | 'country' | 'postCode'>
    ) }
  )>>, orders?: Maybe<Array<(
    { __typename?: 'Order' }
    & Pick<IOrder, 'id' | 'status' | 'total' | 'discountValue'>
    & { paymentTransactions?: Maybe<Array<(
      { __typename?: 'PaymentTransaction' }
      & Pick<IPaymentTransaction, 'id' | 'status'>
    )>>, items?: Maybe<Array<(
      { __typename?: 'OrderItem' }
      & Pick<IOrderItem, 'id' | 'totalPrice'>
      & { course?: Maybe<(
        { __typename: 'StudioCourse' }
        & IStudioCourseFragmentFragment
      ) | (
        { __typename: 'OnlineCourse' }
        & IOnlineCourseFragmentFragment
      )>, exam?: Maybe<(
        { __typename?: 'Exam' }
        & IExamFragmentFragment
      )> }
    )>> }
  )>> }
);

export type IPlaceOrderMutationVariables = Exact<{
  data: IPlaceOrderInput;
}>;


export type IPlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder: (
    { __typename?: 'PlaceOrderResponse' }
    & Pick<IPlaceOrderResponse, 'status' | 'paymentTransactionStatus'>
    & { nextAction?: Maybe<(
      { __typename?: 'PaymentNextAction' }
      & Pick<IPaymentNextAction, 'type' | 'clientSecret'>
      & { redirectToUrl?: Maybe<(
        { __typename?: 'RedirectToURL' }
        & Pick<IRedirectToUrl, 'url' | 'returnUrl'>
      )> }
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<IOrder, 'id' | 'status'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IGetCourseSessionQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type IGetCourseSessionQuery = (
  { __typename?: 'Query' }
  & { getCourseSession: (
    { __typename?: 'CourseSession' }
    & Pick<ICourseSession, 'startDateTime' | 'endDateTime' | 'price' | 'purchasedCourse'>
    & { course?: Maybe<(
      { __typename: 'StudioCourse' }
      & IStudioCourseFragmentFragment
    ) | (
      { __typename: 'OnlineCourse' }
      & IOnlineCourseFragmentFragment
    )> }
  ) }
);

export type IGetCoursesQueryVariables = Exact<{
  data: ICourseSearchInput;
}>;


export type IGetCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: (
    { __typename?: 'CourseResponse' }
    & { courses?: Maybe<Array<(
      { __typename: 'StudioCourse' }
      & IStudioCourseFragmentFragment
    ) | (
      { __typename: 'OnlineCourse' }
      & IOnlineCourseFragmentFragment
    )>>, courseGroups?: Maybe<Array<(
      { __typename?: 'CourseGroup' }
      & ICourseGroupFragmentFragment
    )>> }
  ) }
);

export type IGetCourseQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type IGetCourseQuery = (
  { __typename?: 'Query' }
  & { getCourse: (
    { __typename: 'StudioCourse' }
    & IStudioCourseFragmentFragment
  ) | (
    { __typename: 'OnlineCourse' }
    & IOnlineCourseFragmentFragment
  ) }
);

export type IGetSearchablesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetSearchablesQuery = (
  { __typename?: 'Query' }
  & { getSearchables: (
    { __typename?: 'Searchable' }
    & Pick<ISearchable, 'locations' | 'danceTypes'>
  ) }
);

export type IGetExamsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetExamsQuery = (
  { __typename?: 'Query' }
  & { getExams: Array<(
    { __typename?: 'ExamGroup' }
    & IExamGroupFragmentFragment
  )> }
);

export type IGetCourseGroupQueryVariables = Exact<{
  id: Scalars['Float'];
  displayStatus?: Maybe<IDisplayStatus>;
}>;


export type IGetCourseGroupQuery = (
  { __typename?: 'Query' }
  & { getCourseGroup: (
    { __typename?: 'CourseGroup' }
    & ICourseGroupFragmentFragment
  ) }
);

export type ILogEventMutationVariables = Exact<{
  key: Scalars['String'];
  metadata: Scalars['String'];
}>;


export type ILogEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'logEvent'>
);

export type IMeDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type IMeDetailsQuery = (
  { __typename?: 'Query' }
  & { meDetails: (
    { __typename?: 'UserResponse' }
    & Pick<IUserResponse, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & IUserDetailedFragmentFragment
    )>, children?: Maybe<Array<(
      { __typename?: 'User' }
      & IUserDetailedFragmentFragment
    )>> }
  ) }
);

export type IMeCreditQueryVariables = Exact<{ [key: string]: never; }>;


export type IMeCreditQuery = (
  { __typename?: 'Query' }
  & { meCredit: (
    { __typename?: 'UserResponse' }
    & Pick<IUserResponse, 'creditAvailable'>
  ) }
);

export type IGetUnsettledOrdersQueryVariables = Exact<{
  data: IOrderInput;
}>;


export type IGetUnsettledOrdersQuery = (
  { __typename?: 'Query' }
  & { getUnsettledOrders: (
    { __typename?: 'OrderResponse' }
    & { orders?: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<IOrder, 'id' | 'selectedPaymentMethodType' | 'paidAmount' | 'outstandingAmount' | 'subTotal' | 'isSettled' | 'total' | 'status' | 'createdAt'>
      & { items?: Maybe<Array<(
        { __typename?: 'OrderItem' }
        & Pick<IOrderItem, 'id' | 'totalPrice'>
        & { course?: Maybe<(
          { __typename: 'StudioCourse' }
          & IStudioCourseFragmentFragment
        ) | (
          { __typename: 'OnlineCourse' }
          & IOnlineCourseFragmentFragment
        )> }
      )>>, paymentTransactions?: Maybe<Array<(
        { __typename?: 'PaymentTransaction' }
        & Pick<IPaymentTransaction, 'amount' | 'paymentMethodType' | 'status'>
      )>> }
    )>> }
  ) }
);

export type IUpdatePaymentIntentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type IUpdatePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & Pick<IMutation, 'updatePaymentIntent'>
);

export type IPasswordResetRequestMutationVariables = Exact<{
  data: IPasswordResetReqInput;
}>;


export type IPasswordResetRequestMutation = (
  { __typename?: 'Mutation' }
  & { passwordResetRequest: (
    { __typename?: 'PasswordResetResponse' }
    & Pick<IPasswordResetResponse, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type IPasswordResetMutationVariables = Exact<{
  data: IPasswordResetInput;
}>;


export type IPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { passwordReset: (
    { __typename?: 'PasswordResetResponse' }
    & Pick<IPasswordResetResponse, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<IFieldError, 'path' | 'message'>
    )>> }
  ) }
);

export const OrderModifierFragmentFragmentDoc = gql`
    fragment orderModifierFragment on OrderModifier {
  name
  value
  valueType
}
    `;
export const LineItemFragmentFragmentDoc = gql`
    fragment lineItemFragment on LineItem {
  id
  course {
    id
    name
    price
    currentPrice
    originalPrice
    active
    ageFrom
    level
    attendanceTypes
    courseGroup {
      name
    }
  }
  courseSession {
    id
    price
    course {
      __typename
      ... on StudioCourse {
        name
        attendanceTypes
      }
      ... on OnlineCourse {
        name
        attendanceTypes
      }
    }
  }
  exam {
    id
    name
  }
  orderModifier {
    ...orderModifierFragment
  }
  price
  discountValue
  totalPrice
}
    ${OrderModifierFragmentFragmentDoc}`;
export const BasketFieldsFragmentFragmentDoc = gql`
    fragment basketFieldsFragment on Basket {
  id
  items {
    ...lineItemFragment
  }
  creditItems {
    id
    description
    value
  }
  feeItems {
    id
    description
    value
  }
  orderModifiers {
    ...orderModifierFragment
  }
  discountValue
  discountTotal
  creditTotal
  subTotal
  tax
  total
  chargeTotal
  payLater
}
    ${LineItemFragmentFragmentDoc}
${OrderModifierFragmentFragmentDoc}`;
export const StudioCourseFragmentFragmentDoc = gql`
    fragment StudioCourseFragment on StudioCourse {
  id
  name
  subtitle
  courseGroup {
    id
    name
  }
  ageFrom
  ageTo
  active
  level
  price
  originalPrice
  currentPrice
  depositPrice
  fullyBooked
  thumbImage
  image
  shortDescription
  description
  attendanceTypes
  startDateTime
  endDateTime
  weeks
  order
  listStyle
  days
  location
  danceType
  videos {
    id
    name
    description
    url
    provider
  }
  hasTasterClasses
  tasterPrice
  isAcceptingDeposits
  futureCourseSessions {
    id
    startDateTime
    endDateTime
  }
  sessions {
    id
    startDateTime
    endDateTime
  }
  instructions
  address {
    line1
    line2
    postCode
    city
    county
    county
  }
}
    `;
export const OnlineCourseFragmentFragmentDoc = gql`
    fragment OnlineCourseFragment on OnlineCourse {
  id
  name
  subtitle
  courseGroup {
    id
    name
  }
  ageFrom
  ageTo
  active
  level
  price
  originalPrice
  currentPrice
  depositPrice
  fullyBooked
  thumbImage
  image
  shortDescription
  description
  attendanceTypes
  startDateTime
  endDateTime
  weeks
  order
  listStyle
  days
  hasTasterClasses
  isAcceptingDeposits
  danceType
  location
  tasterPrice
  futureCourseSessions {
    id
    startDateTime
    endDateTime
  }
  sessions {
    id
    startDateTime
    endDateTime
  }
  videos {
    id
    name
    description
    url
    provider
  }
  zoomMeeting {
    meetingId
    password
  }
}
    `;
export const CourseGroupFragmentFragmentDoc = gql`
    fragment CourseGroupFragment on CourseGroup {
  id
  name
  thumbImage
  image
  shortDescription
  description
  minOriginalPrice
  maxOriginalPrice
  minPrice
  maxPrice
  attendanceTypes
  locations
  danceType
  courseTypes
  courses {
    __typename
    ... on StudioCourse {
      ...StudioCourseFragment
    }
    ... on OnlineCourse {
      ...OnlineCourseFragment
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}`;
export const ExamFragmentFragmentDoc = gql`
    fragment ExamFragment on Exam {
  id
  name
  price
  description
  date
  attendanceType
  address {
    name
    line1
    line2
    city
    postCode
  }
}
    `;
export const ExamGroupFragmentFragmentDoc = gql`
    fragment ExamGroupFragment on ExamGroup {
  id
  name
  exams {
    ...ExamFragment
  }
}
    ${ExamFragmentFragmentDoc}`;
export const UserBasicFragmentFragmentDoc = gql`
    fragment UserBasicFragment on User {
  id
  firstName
  lastName
  email
  profileImageUrl
  address {
    line1
    line2
    city
    county
    country
    postCode
  }
  stripeCustomerId
  roles
}
    `;
export const UserDetailedFragmentFragmentDoc = gql`
    fragment UserDetailedFragment on User {
  id
  firstName
  lastName
  email
  profileImageUrl
  password
  address {
    line1
    line2
    city
    county
    country
    postCode
  }
  stripeCustomerId
  courses {
    __typename
    ... on StudioCourse {
      ...StudioCourseFragment
    }
    ... on OnlineCourse {
      ...OnlineCourseFragment
    }
  }
  courseSessions {
    id
    startDateTime
    endDateTime
    purchasedCourse
  }
  exams {
    id
    name
    date
    examGroup {
      name
    }
    attendanceType
    address {
      name
      line1
      line2
      city
      county
      country
      postCode
    }
  }
  orders {
    id
    status
    total
    discountValue
    paymentTransactions {
      id
      status
    }
    items {
      id
      course {
        __typename
        ... on StudioCourse {
          ...StudioCourseFragment
        }
        ... on OnlineCourse {
          ...OnlineCourseFragment
        }
      }
      exam {
        ... on Exam {
          ...ExamFragment
        }
      }
      totalPrice
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}
${ExamFragmentFragmentDoc}`;
export const InitBasketDocument = gql`
    mutation InitBasket {
  initBasket {
    basket {
      ...basketFieldsFragment
    }
    errors {
      path
      message
    }
  }
}
    ${BasketFieldsFragmentFragmentDoc}`;
export type IInitBasketMutationFn = Apollo.MutationFunction<IInitBasketMutation, IInitBasketMutationVariables>;

/**
 * __useInitBasketMutation__
 *
 * To run a mutation, you first call `useInitBasketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitBasketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initBasketMutation, { data, loading, error }] = useInitBasketMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitBasketMutation(baseOptions?: Apollo.MutationHookOptions<IInitBasketMutation, IInitBasketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IInitBasketMutation, IInitBasketMutationVariables>(InitBasketDocument, options);
      }
export type InitBasketMutationHookResult = ReturnType<typeof useInitBasketMutation>;
export type InitBasketMutationResult = Apollo.MutationResult<IInitBasketMutation>;
export type InitBasketMutationOptions = Apollo.BaseMutationOptions<IInitBasketMutation, IInitBasketMutationVariables>;
export const GetBasketDocument = gql`
    query GetBasket {
  getBasket {
    basket {
      ...basketFieldsFragment
    }
    errors {
      path
      message
    }
  }
}
    ${BasketFieldsFragmentFragmentDoc}`;

/**
 * __useGetBasketQuery__
 *
 * To run a query within a React component, call `useGetBasketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasketQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasketQuery(baseOptions?: Apollo.QueryHookOptions<IGetBasketQuery, IGetBasketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetBasketQuery, IGetBasketQueryVariables>(GetBasketDocument, options);
      }
export function useGetBasketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetBasketQuery, IGetBasketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetBasketQuery, IGetBasketQueryVariables>(GetBasketDocument, options);
        }
export type GetBasketQueryHookResult = ReturnType<typeof useGetBasketQuery>;
export type GetBasketLazyQueryHookResult = ReturnType<typeof useGetBasketLazyQuery>;
export type GetBasketQueryResult = Apollo.QueryResult<IGetBasketQuery, IGetBasketQueryVariables>;
export const AddItemDocument = gql`
    mutation AddItem($itemId: Float!, $itemType: String!, $payDeposit: Boolean) {
  addItem(itemId: $itemId, itemType: $itemType, payDeposit: $payDeposit) {
    basket {
      ...basketFieldsFragment
    }
    errors {
      path
      message
    }
  }
}
    ${BasketFieldsFragmentFragmentDoc}`;
export type IAddItemMutationFn = Apollo.MutationFunction<IAddItemMutation, IAddItemMutationVariables>;

/**
 * __useAddItemMutation__
 *
 * To run a mutation, you first call `useAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemMutation, { data, loading, error }] = useAddItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *      payDeposit: // value for 'payDeposit'
 *   },
 * });
 */
export function useAddItemMutation(baseOptions?: Apollo.MutationHookOptions<IAddItemMutation, IAddItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAddItemMutation, IAddItemMutationVariables>(AddItemDocument, options);
      }
export type AddItemMutationHookResult = ReturnType<typeof useAddItemMutation>;
export type AddItemMutationResult = Apollo.MutationResult<IAddItemMutation>;
export type AddItemMutationOptions = Apollo.BaseMutationOptions<IAddItemMutation, IAddItemMutationVariables>;
export const RemoveItemDocument = gql`
    mutation RemoveItem($itemId: Float!, $itemType: String!) {
  removeItem(itemId: $itemId, itemType: $itemType) {
    basket {
      ...basketFieldsFragment
    }
    errors {
      path
      message
    }
  }
}
    ${BasketFieldsFragmentFragmentDoc}`;
export type IRemoveItemMutationFn = Apollo.MutationFunction<IRemoveItemMutation, IRemoveItemMutationVariables>;

/**
 * __useRemoveItemMutation__
 *
 * To run a mutation, you first call `useRemoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemMutation, { data, loading, error }] = useRemoveItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *   },
 * });
 */
export function useRemoveItemMutation(baseOptions?: Apollo.MutationHookOptions<IRemoveItemMutation, IRemoveItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRemoveItemMutation, IRemoveItemMutationVariables>(RemoveItemDocument, options);
      }
export type RemoveItemMutationHookResult = ReturnType<typeof useRemoveItemMutation>;
export type RemoveItemMutationResult = Apollo.MutationResult<IRemoveItemMutation>;
export type RemoveItemMutationOptions = Apollo.BaseMutationOptions<IRemoveItemMutation, IRemoveItemMutationVariables>;
export const GetCourseVideosDocument = gql`
    query GetCourseVideos($data: CourseVideoInput!) {
  getCourseVideos(data: $data) {
    id
    name
    description
    url
    provider
  }
}
    `;

/**
 * __useGetCourseVideosQuery__
 *
 * To run a query within a React component, call `useGetCourseVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseVideosQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCourseVideosQuery(baseOptions: Apollo.QueryHookOptions<IGetCourseVideosQuery, IGetCourseVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCourseVideosQuery, IGetCourseVideosQueryVariables>(GetCourseVideosDocument, options);
      }
export function useGetCourseVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCourseVideosQuery, IGetCourseVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCourseVideosQuery, IGetCourseVideosQueryVariables>(GetCourseVideosDocument, options);
        }
export type GetCourseVideosQueryHookResult = ReturnType<typeof useGetCourseVideosQuery>;
export type GetCourseVideosLazyQueryHookResult = ReturnType<typeof useGetCourseVideosLazyQuery>;
export type GetCourseVideosQueryResult = Apollo.QueryResult<IGetCourseVideosQuery, IGetCourseVideosQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    user {
      ...UserBasicFragment
    }
  }
}
    ${UserBasicFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<IMeQuery, IMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMeQuery, IMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMeQuery, IMeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<IMeQuery, IMeQueryVariables>;
export const LoginGetDocument = gql`
    query LoginGet($data: LoginInput!) {
  loginGet(data: $data) {
    user {
      email
      firstName
      lastName
    }
    token
    errors {
      path
      message
    }
  }
}
    `;

/**
 * __useLoginGetQuery__
 *
 * To run a query within a React component, call `useLoginGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginGetQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginGetQuery(baseOptions: Apollo.QueryHookOptions<ILoginGetQuery, ILoginGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoginGetQuery, ILoginGetQueryVariables>(LoginGetDocument, options);
      }
export function useLoginGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoginGetQuery, ILoginGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoginGetQuery, ILoginGetQueryVariables>(LoginGetDocument, options);
        }
export type LoginGetQueryHookResult = ReturnType<typeof useLoginGetQuery>;
export type LoginGetLazyQueryHookResult = ReturnType<typeof useLoginGetLazyQuery>;
export type LoginGetQueryResult = Apollo.QueryResult<ILoginGetQuery, ILoginGetQueryVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user {
      email
      firstName
      lastName
    }
    token
    errors {
      path
      message
    }
  }
}
    `;
export type ILoginMutationFn = Apollo.MutationFunction<ILoginMutation, ILoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const LogOutDocument = gql`
    mutation LogOut {
  logout
}
    `;
export type ILogOutMutationFn = Apollo.MutationFunction<ILogOutMutation, ILogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<ILogOutMutation, ILogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILogOutMutation, ILogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<ILogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<ILogOutMutation, ILogOutMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($data: CreateUserInput!) {
  register(data: $data) {
    user {
      firstName
      lastName
      email
    }
    errors {
      path
      message
    }
  }
}
    `;
export type IRegisterMutationFn = Apollo.MutationFunction<IRegisterMutation, IRegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<IRegisterMutation, IRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRegisterMutation, IRegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<IRegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<IRegisterMutation, IRegisterMutationVariables>;
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods {
  getPaymentMethods {
    paymentMethods {
      id
      card {
        brand
        last4
        expYear
        expMonth
        country
      }
    }
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<IGetPaymentMethodsQuery, IGetPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPaymentMethodsQuery, IGetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPaymentMethodsQuery, IGetPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPaymentMethodsQuery, IGetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<IGetPaymentMethodsQuery, IGetPaymentMethodsQueryVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($data: PlaceOrderInput!) {
  placeOrder(data: $data) {
    status
    nextAction {
      type
      clientSecret
      redirectToUrl {
        url
        returnUrl
      }
    }
    order {
      id
      status
    }
    paymentTransactionStatus
    errors {
      path
      message
    }
  }
}
    `;
export type IPlaceOrderMutationFn = Apollo.MutationFunction<IPlaceOrderMutation, IPlaceOrderMutationVariables>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: Apollo.MutationHookOptions<IPlaceOrderMutation, IPlaceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPlaceOrderMutation, IPlaceOrderMutationVariables>(PlaceOrderDocument, options);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = Apollo.MutationResult<IPlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<IPlaceOrderMutation, IPlaceOrderMutationVariables>;
export const GetCourseSessionDocument = gql`
    query GetCourseSession($id: Float!) {
  getCourseSession(id: $id) {
    startDateTime
    endDateTime
    price
    purchasedCourse
    course {
      __typename
      ... on StudioCourse {
        ...StudioCourseFragment
      }
      ... on OnlineCourse {
        ...OnlineCourseFragment
      }
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}`;

/**
 * __useGetCourseSessionQuery__
 *
 * To run a query within a React component, call `useGetCourseSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseSessionQuery(baseOptions: Apollo.QueryHookOptions<IGetCourseSessionQuery, IGetCourseSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCourseSessionQuery, IGetCourseSessionQueryVariables>(GetCourseSessionDocument, options);
      }
export function useGetCourseSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCourseSessionQuery, IGetCourseSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCourseSessionQuery, IGetCourseSessionQueryVariables>(GetCourseSessionDocument, options);
        }
export type GetCourseSessionQueryHookResult = ReturnType<typeof useGetCourseSessionQuery>;
export type GetCourseSessionLazyQueryHookResult = ReturnType<typeof useGetCourseSessionLazyQuery>;
export type GetCourseSessionQueryResult = Apollo.QueryResult<IGetCourseSessionQuery, IGetCourseSessionQueryVariables>;
export const GetCoursesDocument = gql`
    query GetCourses($data: CourseSearchInput!) {
  getCourses(data: $data) {
    courses {
      __typename
      ... on StudioCourse {
        ...StudioCourseFragment
      }
      ... on OnlineCourse {
        ...OnlineCourseFragment
      }
    }
    courseGroups {
      ...CourseGroupFragment
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}
${CourseGroupFragmentFragmentDoc}`;

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions: Apollo.QueryHookOptions<IGetCoursesQuery, IGetCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCoursesQuery, IGetCoursesQueryVariables>(GetCoursesDocument, options);
      }
export function useGetCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCoursesQuery, IGetCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCoursesQuery, IGetCoursesQueryVariables>(GetCoursesDocument, options);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = Apollo.QueryResult<IGetCoursesQuery, IGetCoursesQueryVariables>;
export const GetCourseDocument = gql`
    query GetCourse($id: Float!) {
  getCourse(id: $id) {
    __typename
    ... on StudioCourse {
      ...StudioCourseFragment
    }
    ... on OnlineCourse {
      ...OnlineCourseFragment
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}`;

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseQuery(baseOptions: Apollo.QueryHookOptions<IGetCourseQuery, IGetCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCourseQuery, IGetCourseQueryVariables>(GetCourseDocument, options);
      }
export function useGetCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCourseQuery, IGetCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCourseQuery, IGetCourseQueryVariables>(GetCourseDocument, options);
        }
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>;
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>;
export type GetCourseQueryResult = Apollo.QueryResult<IGetCourseQuery, IGetCourseQueryVariables>;
export const GetSearchablesDocument = gql`
    query GetSearchables {
  getSearchables {
    locations
    danceTypes
  }
}
    `;

/**
 * __useGetSearchablesQuery__
 *
 * To run a query within a React component, call `useGetSearchablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchablesQuery(baseOptions?: Apollo.QueryHookOptions<IGetSearchablesQuery, IGetSearchablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSearchablesQuery, IGetSearchablesQueryVariables>(GetSearchablesDocument, options);
      }
export function useGetSearchablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSearchablesQuery, IGetSearchablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSearchablesQuery, IGetSearchablesQueryVariables>(GetSearchablesDocument, options);
        }
export type GetSearchablesQueryHookResult = ReturnType<typeof useGetSearchablesQuery>;
export type GetSearchablesLazyQueryHookResult = ReturnType<typeof useGetSearchablesLazyQuery>;
export type GetSearchablesQueryResult = Apollo.QueryResult<IGetSearchablesQuery, IGetSearchablesQueryVariables>;
export const GetExamsDocument = gql`
    query GetExams {
  getExams {
    ...ExamGroupFragment
  }
}
    ${ExamGroupFragmentFragmentDoc}`;

/**
 * __useGetExamsQuery__
 *
 * To run a query within a React component, call `useGetExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExamsQuery(baseOptions?: Apollo.QueryHookOptions<IGetExamsQuery, IGetExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetExamsQuery, IGetExamsQueryVariables>(GetExamsDocument, options);
      }
export function useGetExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetExamsQuery, IGetExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetExamsQuery, IGetExamsQueryVariables>(GetExamsDocument, options);
        }
export type GetExamsQueryHookResult = ReturnType<typeof useGetExamsQuery>;
export type GetExamsLazyQueryHookResult = ReturnType<typeof useGetExamsLazyQuery>;
export type GetExamsQueryResult = Apollo.QueryResult<IGetExamsQuery, IGetExamsQueryVariables>;
export const GetCourseGroupDocument = gql`
    query GetCourseGroup($id: Float!, $displayStatus: DisplayStatus) {
  getCourseGroup(id: $id, displayStatus: $displayStatus) {
    ...CourseGroupFragment
  }
}
    ${CourseGroupFragmentFragmentDoc}`;

/**
 * __useGetCourseGroupQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      displayStatus: // value for 'displayStatus'
 *   },
 * });
 */
export function useGetCourseGroupQuery(baseOptions: Apollo.QueryHookOptions<IGetCourseGroupQuery, IGetCourseGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCourseGroupQuery, IGetCourseGroupQueryVariables>(GetCourseGroupDocument, options);
      }
export function useGetCourseGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCourseGroupQuery, IGetCourseGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCourseGroupQuery, IGetCourseGroupQueryVariables>(GetCourseGroupDocument, options);
        }
export type GetCourseGroupQueryHookResult = ReturnType<typeof useGetCourseGroupQuery>;
export type GetCourseGroupLazyQueryHookResult = ReturnType<typeof useGetCourseGroupLazyQuery>;
export type GetCourseGroupQueryResult = Apollo.QueryResult<IGetCourseGroupQuery, IGetCourseGroupQueryVariables>;
export const LogEventDocument = gql`
    mutation LogEvent($key: String!, $metadata: String!) {
  logEvent(key: $key, metadata: $metadata)
}
    `;
export type ILogEventMutationFn = Apollo.MutationFunction<ILogEventMutation, ILogEventMutationVariables>;

/**
 * __useLogEventMutation__
 *
 * To run a mutation, you first call `useLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logEventMutation, { data, loading, error }] = useLogEventMutation({
 *   variables: {
 *      key: // value for 'key'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useLogEventMutation(baseOptions?: Apollo.MutationHookOptions<ILogEventMutation, ILogEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILogEventMutation, ILogEventMutationVariables>(LogEventDocument, options);
      }
export type LogEventMutationHookResult = ReturnType<typeof useLogEventMutation>;
export type LogEventMutationResult = Apollo.MutationResult<ILogEventMutation>;
export type LogEventMutationOptions = Apollo.BaseMutationOptions<ILogEventMutation, ILogEventMutationVariables>;
export const MeDetailsDocument = gql`
    query MeDetails {
  meDetails {
    user {
      ...UserDetailedFragment
    }
    children {
      ...UserDetailedFragment
    }
    token
  }
}
    ${UserDetailedFragmentFragmentDoc}`;

/**
 * __useMeDetailsQuery__
 *
 * To run a query within a React component, call `useMeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeDetailsQuery(baseOptions?: Apollo.QueryHookOptions<IMeDetailsQuery, IMeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMeDetailsQuery, IMeDetailsQueryVariables>(MeDetailsDocument, options);
      }
export function useMeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMeDetailsQuery, IMeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMeDetailsQuery, IMeDetailsQueryVariables>(MeDetailsDocument, options);
        }
export type MeDetailsQueryHookResult = ReturnType<typeof useMeDetailsQuery>;
export type MeDetailsLazyQueryHookResult = ReturnType<typeof useMeDetailsLazyQuery>;
export type MeDetailsQueryResult = Apollo.QueryResult<IMeDetailsQuery, IMeDetailsQueryVariables>;
export const MeCreditDocument = gql`
    query MeCredit {
  meCredit {
    creditAvailable
  }
}
    `;

/**
 * __useMeCreditQuery__
 *
 * To run a query within a React component, call `useMeCreditQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeCreditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeCreditQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeCreditQuery(baseOptions?: Apollo.QueryHookOptions<IMeCreditQuery, IMeCreditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMeCreditQuery, IMeCreditQueryVariables>(MeCreditDocument, options);
      }
export function useMeCreditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMeCreditQuery, IMeCreditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMeCreditQuery, IMeCreditQueryVariables>(MeCreditDocument, options);
        }
export type MeCreditQueryHookResult = ReturnType<typeof useMeCreditQuery>;
export type MeCreditLazyQueryHookResult = ReturnType<typeof useMeCreditLazyQuery>;
export type MeCreditQueryResult = Apollo.QueryResult<IMeCreditQuery, IMeCreditQueryVariables>;
export const GetUnsettledOrdersDocument = gql`
    query GetUnsettledOrders($data: OrderInput!) {
  getUnsettledOrders(data: $data) {
    orders {
      id
      items {
        id
        course {
          __typename
          ... on StudioCourse {
            ...StudioCourseFragment
          }
          ... on OnlineCourse {
            ...OnlineCourseFragment
          }
        }
        totalPrice
      }
      paymentTransactions {
        amount
        paymentMethodType
        status
      }
      selectedPaymentMethodType
      paidAmount
      outstandingAmount
      subTotal
      isSettled
      total
      status
      createdAt
    }
  }
}
    ${StudioCourseFragmentFragmentDoc}
${OnlineCourseFragmentFragmentDoc}`;

/**
 * __useGetUnsettledOrdersQuery__
 *
 * To run a query within a React component, call `useGetUnsettledOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnsettledOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnsettledOrdersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUnsettledOrdersQuery(baseOptions: Apollo.QueryHookOptions<IGetUnsettledOrdersQuery, IGetUnsettledOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUnsettledOrdersQuery, IGetUnsettledOrdersQueryVariables>(GetUnsettledOrdersDocument, options);
      }
export function useGetUnsettledOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUnsettledOrdersQuery, IGetUnsettledOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUnsettledOrdersQuery, IGetUnsettledOrdersQueryVariables>(GetUnsettledOrdersDocument, options);
        }
export type GetUnsettledOrdersQueryHookResult = ReturnType<typeof useGetUnsettledOrdersQuery>;
export type GetUnsettledOrdersLazyQueryHookResult = ReturnType<typeof useGetUnsettledOrdersLazyQuery>;
export type GetUnsettledOrdersQueryResult = Apollo.QueryResult<IGetUnsettledOrdersQuery, IGetUnsettledOrdersQueryVariables>;
export const UpdatePaymentIntentDocument = gql`
    mutation UpdatePaymentIntent($id: String!) {
  updatePaymentIntent(id: $id)
}
    `;
export type IUpdatePaymentIntentMutationFn = Apollo.MutationFunction<IUpdatePaymentIntentMutation, IUpdatePaymentIntentMutationVariables>;

/**
 * __useUpdatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentIntentMutation, { data, loading, error }] = useUpdatePaymentIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<IUpdatePaymentIntentMutation, IUpdatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdatePaymentIntentMutation, IUpdatePaymentIntentMutationVariables>(UpdatePaymentIntentDocument, options);
      }
export type UpdatePaymentIntentMutationHookResult = ReturnType<typeof useUpdatePaymentIntentMutation>;
export type UpdatePaymentIntentMutationResult = Apollo.MutationResult<IUpdatePaymentIntentMutation>;
export type UpdatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<IUpdatePaymentIntentMutation, IUpdatePaymentIntentMutationVariables>;
export const PasswordResetRequestDocument = gql`
    mutation PasswordResetRequest($data: PasswordResetReqInput!) {
  passwordResetRequest(data: $data) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type IPasswordResetRequestMutationFn = Apollo.MutationFunction<IPasswordResetRequestMutation, IPasswordResetRequestMutationVariables>;

/**
 * __usePasswordResetRequestMutation__
 *
 * To run a mutation, you first call `usePasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetRequestMutation, { data, loading, error }] = usePasswordResetRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordResetRequestMutation(baseOptions?: Apollo.MutationHookOptions<IPasswordResetRequestMutation, IPasswordResetRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPasswordResetRequestMutation, IPasswordResetRequestMutationVariables>(PasswordResetRequestDocument, options);
      }
export type PasswordResetRequestMutationHookResult = ReturnType<typeof usePasswordResetRequestMutation>;
export type PasswordResetRequestMutationResult = Apollo.MutationResult<IPasswordResetRequestMutation>;
export type PasswordResetRequestMutationOptions = Apollo.BaseMutationOptions<IPasswordResetRequestMutation, IPasswordResetRequestMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($data: PasswordResetInput!) {
  passwordReset(data: $data) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type IPasswordResetMutationFn = Apollo.MutationFunction<IPasswordResetMutation, IPasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<IPasswordResetMutation, IPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IPasswordResetMutation, IPasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<IPasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<IPasswordResetMutation, IPasswordResetMutationVariables>;